import {
  Alert,
  Snackbar,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import LoginIcon from '@mui/icons-material/Login'
import { Redirect, useHistory } from 'react-router-dom'
import SubscriptionService from '../../api-services/subscription-service'
import { useTranslation } from 'react-i18next'

const Unsubscribe = () => {
	const { t, i18n } = useTranslation()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [unsubForm, setUnsubForm] = useState({ reason: '' })

  const history = useHistory()

  const handleOnChange = e => {
    const { name, value } = e.target

    setUnsubForm(prevUserData => ({
      ...prevUserData,
      [name]: value,
    }))
  }

  const handleUnsubscribe = async e => {
    e.preventDefault()

    setLoading(true)

    const unsubFormData = new FormData()
    unsubFormData.append('reason', unsubForm.reason)

    const response = await SubscriptionService.cancel(unsubFormData)

    if (!response.error) {
      setLoading(false)

      localStorage.setItem('plan_id', "")
      localStorage.setItem('subscription_id', "")
      localStorage.setItem('plan_activated', false)

      history.push('/plans')

      console.log(response)

    } else if (response.error) {
      setLoading(false)
      setError(response.msg)
      setAlertOpen(true)
    }
    else if (response === undefined)
      setLoading(false)
  }

  const handleClose = () => {
    setAlertOpen(false)
  }

  return (
    <>
      {!localStorage.getItem('authenticated')
        ? <Redirect to='/' />
        : (localStorage.getItem('plan_activated') !== null || localStorage.getItem('plan_activated') === 'true')
          ? <Box
            sx={{
              marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
              marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                // marginTop: '7%',
                marginTop: '1%',
                alignItems: 'center',
                border:'1px solid #3a3a3a',
                borderRadius: "12px",
                height: { lg: "80vh", md: '80vh', sm: '80vh', xs: '80vh' },
                flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' },
              }}
            >
              <Box
                sx={{
                  // background: "#b3e6ff",
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: { lg: "12px" },
                  height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
                  width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
                  borderTopRightRadius: { md: '12px', lg: 0, sm: '12px', xs: '12px' },
                }}
              />
              <Box
                sx={{
                  background: "#e6e6e6",
                  borderBottomRightRadius: "12px",
                  height: { lg: '100%', md: '70%' },
                  borderTopRightRadius: { lg: "12px" },
                  width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
                  borderBottomLeftRadius: { md: "12px", lg: 0, sm: '12px', xs: '12px' },
                }}
              >
                <Box
                  sx={{
                    mx: 8,
                    height: "70%",
                    marginTop: { lg: "25%" }
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: '30px' }} variant="button" display="block">
                      {t('unsub')}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "70%",
                      mt: 5
                    }}
                  >
                    <form style={{ height: "100%", marginBottom: "20%" }} onSubmit={handleUnsubscribe}>
                      <Box
                        sx={{
                          textAlign: 'center'
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-reason">{t('reason')}</InputLabel>
                          <OutlinedInput
                            type="textarea"
                            name="reason"
                            label={t('reason')}
                            variant="outlined"
                            onChange={handleOnChange}
                            id="outlined-adornment-reason"
                          />
                        </FormControl>
                      </Box>

                      <Box
                        sx={{
                          textAlign: 'center',
                          mt: 2
                        }}
                      >
                        <LoadingButton
                          disabled={unsubForm.reason === ''}
                          loading={loading}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                          endIcon={<LoginIcon />}
                        >
                          {t('unsub')}
                        </LoadingButton>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={alertOpen}
              autoHideDuration={2500}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          </Box>
          : <Redirect
            to={{
              pathname: '/dashboard'
            }}
          />
      }
    </>
  )
}

export default Unsubscribe
