import {
  Box,
  Alert,
  Select,
  MenuItem,
  Snackbar,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import UserService from '../../api-services/user-service'
import { useTranslation } from 'react-i18next'

const AddUser = ({ roles, setUsers }) => {
	const { t, i18n } = useTranslation()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [addUserForm, setAddUserForm] = useState({ email: '', fullname: '', password: '', role: '' })

  const handleOnChange = e => {
    const { name, value } = e.target

    setAddUserForm(prevUserData => ({
      ...prevUserData,
      [name]: value,
    }))
  }

  const handleAddUser = async e => {
    e.preventDefault()

    setLoading(true)

    const userFormData = new FormData()
    userFormData.append('email', addUserForm.email)
    userFormData.append('role_id', addUserForm.role)
    userFormData.append('fullname', addUserForm.fullname)

    const response = await UserService.add(userFormData)

    if (!response.error) {
      setLoading(false)
      setAlertOpen(true)

      const userServiceResponse = await UserService.list()

      setLoading(false)
      if (!userServiceResponse.error) {
        let tempArr = []

        if (userServiceResponse.data !== undefined) {
          for (let i = 0; i < userServiceResponse.data.length; i++) {
            if (userServiceResponse.data[i].id !== localStorage.getItem("user_id"))
              tempArr.push({
                id: userServiceResponse.data[i].id,
                col1: userServiceResponse.data[i].email,
                col2: userServiceResponse.data[i].fullname,
                col4: userServiceResponse.data[i].role_name,
                col5: userServiceResponse.data[i].isActive ? "Active" : "Suspended",
                col6: userServiceResponse.data[i].isVerified ? "Verified" : "Not Verified",
                col7: userServiceResponse.data[i].isApproved ? "Approved" : "Not Approved",
              })
          }
          setUsers(tempArr)
        }
      } else {
        setError("Something went wrong...")
        setAlertOpen(true)
      }
    } else if (response.error) {
      setLoading(false)
      setError(response.msg)
      setAlertOpen(true)
    }
    else if (response === undefined)
      setLoading(false)
  }

  const handleClose = () => {
    setAlertOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          height: "70%",
          width: "100%",
          mx: { lg: 4, md: 4 },
          // marginTop: { lg: "25%" }
        }}
      >
        <Box>
          <Typography sx={{ fontSize: '15px' }} variant="overline" display="block">
            {t('addNewUser')}<br />
          </Typography>
        </Box>
        <form
          onSubmit={handleAddUser}
        >
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-fullname">{t('fullname')}</InputLabel>
              <OutlinedInput
                type='text'
                name="fullname"
                label={t('fullname')}
                variant="outlined"
                onChange={handleOnChange}
                id="outlined-adornment-fullname"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">{t('email')}</InputLabel>
              <OutlinedInput
                type="email"
                name="email"
                label={t('email')}
                variant="outlined"
                onChange={handleOnChange}
                id="outlined-adornment-email"
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">{t('role')}</InputLabel>
              <Select
                name="role"
                label={t('role')}
                id="demo-simple-select"
                value={addUserForm.role}
                onChange={handleOnChange}
                labelId="demo-simple-select-label"
              >
                {roles.map((role, i) => <MenuItem key={i} value={role.id}>{t(role.role_name)}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              textAlign: 'center',
              mt: 2
            }}
          >
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              loadingPosition="start"
              startIcon={<AddIcon />}
              disabled={
                addUserForm.email === ''
                || addUserForm.fullname === ''
                || addUserForm.role === ''
              }
            >
              {t('addUser')}
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <Snackbar
        open={alertOpen}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          sx={{ width: '100%' }}
          severity={error === "" ? "success" : "error"}
        >
          {error === "" ? "User Added Successfully" : error}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AddUser
