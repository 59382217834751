import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import LoginIcon from '@mui/icons-material/Login'
import AuthService from '../../api-services/auth-service'
import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TwoFactorAuth = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [codesForm, setCodesForm] = useState({
		firstChar: '',
		secondChar: '',
		thirdChar: '',
		fourthChar: '',
		fifthChar: '',
		sixthChar: '',
	})

	const history = useHistory()
	let match = useRouteMatch('/:slug')

	const handleClose = () => {
		setAlertOpen(false)
	}

	const handleOnChange = e => {
		const { name, value } = e.target

		// const firstCodeInput = document.getElementById('first')
		const secondCodeInput = document.getElementById('second')
		const thirdCodeInput = document.getElementById('third')
		const fourthCodeInput = document.getElementById('fourth')
		const fifthCodeInput = document.getElementById('fifth')
		const sixthCodeInput = document.getElementById('sixth')

		setCodesForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))

		if (name === 'firstChar') if (value.length === 1) secondCodeInput.focus()

		if (name === 'secondChar') if (value.length === 1) thirdCodeInput.focus()

		if (name === 'thirdChar') if (value.length === 1) fourthCodeInput.focus()

		if (name === 'fourthChar') if (value.length === 1) fifthCodeInput.focus()

		if (name === 'fifthChar') if (value.length === 1) sixthCodeInput.focus()

		// if (name === 'sixthChar')
		//   if (value.length === 1) handleAuthentication()
	}

	const handleAuthentication = async e => {
		e.preventDefault()

		setLoading(true)

		let otp =
			codesForm.firstChar +
			codesForm.secondChar +
			codesForm.thirdChar +
			codesForm.fourthChar +
			codesForm.fifthChar +
			codesForm.sixthChar

		let verificationFormData = new FormData()

		verificationFormData.append('email', localStorage.getItem('email'))
		verificationFormData.append('otp', otp)

		const response = await AuthService.verifyOTP(verificationFormData)

		if (response.error) {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		} else {
			setLoading(false)
			if (history.length <= 4 && localStorage.getItem('id') !== null) {
				history.replace(`/${localStorage.getItem('id')}`)
			} else {
				if (localStorage.getItem('authenticated')) {
					if (localStorage.getItem('role') === 'admin')
						history.push('/admin-dashboard')
					else if (localStorage.getItem('role') === 'user')
						history.push('/dashboard')
				}
			}
		}
	}

	useEffect(
		() => () => {
			setError('')
			setAlertOpen(false)
		},
		[]
	)

	return (
		<>
			{localStorage.getItem('authenticated') ? (
				<Redirect to='/dashboard' />
			) : (
				<Box
					sx={{
						marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
						marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							marginTop: '7%',
							alignItems: 'center',
							border:'1px solid #3a3a3a',
							borderRadius: '12px',
							height: { lg: '80vh', md: '80vh', sm: '80vh', xs: '80vh' },
							flexDirection: {
								lg: 'row',
								md: 'column',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						<Box
							sx={{
								// background: '#b3e6ff',
								background: '#ffff',
								borderTopLeftRadius: '12px',
								borderBottomLeftRadius: { lg: '12px' },
								height: { lg: '100%', md: '35%', sm: '35%', xs: '25%' },
								width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
								borderTopRightRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
							}}
						/>
						<Box
							sx={{
								background: '#e6e6e6',
								borderBottomRightRadius: '12px',
								height: { lg: '100%', md: '70%', sm: '70%', xs: '70%' },
								borderTopRightRadius: { lg: '12px' },
								width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
								borderBottomLeftRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
							}}
						>
							<Box
								sx={{
									height: '70%',
									marginTop: { lg: '25%' },
									mx: { lg: 5, md: 6, sm: 4, xs: 4 },
								}}
							>
								<Box>
									<Typography
										sx={{
											fontSize: {
												lg: '30px',
												md: '30px',
												sm: '20px',
												xs: '20px',
											},
										}}
										variant='button'
										display='block'
									>
										{t('verifyYourAccount')}
									</Typography>
								</Box>
								<Box>
									<Typography
										sx={{ fontSize: '12px' }}
										variant='overline'
										display='block'
									>
										{t('enterCodeFromApp')} <br />
									</Typography>
								</Box>
								<Box
									sx={{
										height: '70%',
										mt: 5,
									}}
								>
									<form
										style={{ height: '100%', marginBottom: '20%' }}
										onSubmit={handleAuthentication}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='first'
													type='text'
													name='firstChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.firstChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>

											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='second'
													name='secondChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.secondChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>

											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='third'
													name='thirdChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.thirdChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>

											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='fourth'
													name='fourthChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.fourthChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>

											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='fifth'
													name='fifthChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.fifthChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>

											<Box
												sx={{
													mr: { lg: 0 },
													textAlign: 'center',
													mx: { md: 1, sm: 1, xs: 1 },
												}}
											>
												<TextField
													id='sixth'
													name='sixthChar'
													variant='outlined'
													onChange={handleOnChange}
													value={codesForm.sixthChar}
													style={{
														textAlign: 'center',
													}}
													inputProps={{ maxLength: 1 }}
												/>
											</Box>
										</Box>

										<Box
											sx={{
												mt: 2,
												textAlign: 'center',
											}}
										>
											<LoadingButton
												type='submit'
												loading={loading}
												variant='contained'
												loadingPosition='end'
												endIcon={<LoginIcon />}
												disabled={
													codesForm.firstChar === '' ||
													codesForm.secondChar === '' ||
													codesForm.thirdChar === '' ||
													codesForm.fourthChar === '' ||
													codesForm.fifthChar === '' ||
													codesForm.sixthChar === ''
												}
											>
												{t('verify')}
											</LoadingButton>
										</Box>
									</form>
								</Box>
							</Box>
						</Box>
					</Box>
					<Snackbar
						open={alertOpen}
						onClose={handleClose}
						autoHideDuration={6000}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							severity='error'
							onClose={handleClose}
							sx={{ width: '100%' }}
						>
							{error}
						</Alert>
					</Snackbar>
				</Box>
			)}
		</>
	)
}

export default TwoFactorAuth
