import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DeleteDialogBox = ({
	open,
	usageFlag,
	deleteUser,
	deletePlan,
	handleClose,
}) => {
	const { t, i18n } = useTranslation()

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle color='error'>{`${
				usageFlag === 'plan'
					? t('deactivateThe') + ' ' + t('plan')
					: t('deleteThe') + ' ' + t('account')
			}?`}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{`${
						usageFlag === 'plan'
							? t('aboutToDeactivate') + ' ' + t('thePlan')
							: t('aboutToDelete') + ' ' + t('anAccount')
					}. ${
						usageFlag === 'plan'
							? t('proceedWithDeactivation')
							: t('proceedWithDeletion')
					}`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{t('cancel')}</Button>
				<Button
					color='error'
					onClick={usageFlag === 'plan' ? deletePlan : deleteUser}
				>
					{usageFlag === 'plan' ? t('deactivatePlan') : t('deleteAcc')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DeleteDialogBox
