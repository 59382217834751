import { LoadingButton } from '@mui/lab'
import {
	Alert,
	AlertTitle,
	IconButton,
	InputLabel,
	Typography,
	FormControl,
	OutlinedInput,
	InputAdornment,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, height, maxHeight } from '@mui/system'
import { Redirect, useRouteMatch } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EncryptionService from '../../../api-services/enc-service'

const EncryptText = () => {
	const { url } = useRouteMatch()
	const { t, i18n } = useTranslation()

	const [loading, setLoading] = useState(false)
	const [responseURL, setResponseURL] = useState('')
	const [URLRemoved, setURLRemoved] = useState(false)
	const [textToEncrypt, setTextToEncrypt] = useState('')

	const handleOnChange = e => {
		const { value } = e.target

		setTextToEncrypt(value)
	}

	const handleEncryptionClick = async () => {
		setLoading(true)

		const encryptionFormData = new FormData()
		encryptionFormData.append('file', '')
		encryptionFormData.append('text', textToEncrypt)

		if (url.includes('guest')) {
			encryptionFormData.append('id', localStorage.getItem('id'))

			const response = await EncryptionService.encryptDataGuest(
				encryptionFormData
			)

			if (!response.error) {
				setLoading(false)
				setTextToEncrypt('')
				setResponseURL(response.url)
			} else {
				setLoading(false)
				if (response.msg === 'Url has been removed') {
					setURLRemoved(true)
				}
			}
		} else {
			encryptionFormData.append('user_id', localStorage.getItem('user_id'))

			const response = await EncryptionService.encryptData(encryptionFormData)

			if (!response.error) {
				setLoading(false)
				setTextToEncrypt('')
				setResponseURL(response.url)
			} else {
				setLoading(false)
				if (response.msg === 'Url has been removed') {
					setURLRemoved(true)
				}
			}
		}
	}

	return (
		<Box
			id='container'
			sx={{
				display: 'flex',
				margin: '2rem',
				alignItems: 'stretch',
				border:'1px solid #3a3a3a',
				borderRadius: { lg: '12px' },
				borderBottomRightRadius: { md: 0, sm: 0, xs: 0 },
				// height: { lg: "70vh", md: '70vh', sm: '70vh', xs: '70vh' },
				minHeight: '75vh',

				borderTopRightRadius: { md: '12px', sm: '12px', xs: '12px' },
				flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' },
			}}
		>
			<Box
				id='secContainer'
				sx={{
					display: 'flex',
					alignItems: 'stretch',
					justifyContent: 'center',
					// background: '#b3e6ff',
					background: '#ffff',
					borderTopLeftRadius: '12px',
					borderBottomRightRadius: { md: 0, sm: 0, xs: 0 },
					// height: { lg: '100%', md: '25%', sm: '23%', xs: '23%' },
					height: {
						lg: '75vh',
						md: '30vh',
						sm: '30vh',
						xs: '100%',
						xl: '75vh',
					},

					width: { lg: '100%', md: '100%', sm: '100%', xs: '100%', xl: '100%' },
					borderBottomLeftRadius: { lg: '12px', md: 0, sm: 0, xs: 0 },
					borderTopRightRadius: { lg: 0, md: '12px', sm: '12px', xs: '12px' },
				}}
			>
				<Box
					id='ThirdContainer'
					sx={{
						height: 'inherit',
						textAlign: 'center',
						width: '100%',
					}}
				>
					<Box
						id='con1'
						sx={{
							height: '100%',
							display: 'flex',
							textAlign: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
							borderTopLeftRadius: '12px',
							borderBottomLeftRadius: '12px',
						}}
					>
						<Typography
							display='block'
							variant='body1'
							sx={{
								lineHeight: '1.4em',
								fontSize: { lg: '20px', md: '20px', sm: '15px', xs: '15px' },
							}}
						>
							{responseURL !== '' && url.includes('guest')
								? t('dataEncrypted')
								: URLRemoved
								? t('dataAccessed')
								: t('enterDataToEncrypt')}
						</Typography>

						{responseURL === '' ? (
							<></>
						) : url === '/guest-encrypt-text' ? (
							<></>
						) : (
							<Box
								id='con2'
								sx={{
									textAlign: 'center',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									display='block'
									variant='subtitle2'
									sx={{
										fontSize: {
											lg: '20px',
											md: '20px',
											sm: '15px',
											xs: '11px',
										},
										textOverflow: {
											sm: 'ellipsis',
											md: 'ellipsis',
											xs: 'ellipsis',
										},
										whiteSpace: { md: 'nowrap', sm: 'nowrap', xs: 'nowrap' },
										overflow: { md: 'hidden', sm: 'hidden', xs: 'hidden' },
										width: {
											lg: '100%',
											sm: '400px',
											md: '700px',
											xs: '150px',
											xl: '100%',
										},
										textAlign: 'center',
										marginLeft: '2px',
									}}
								>
									<p
										id='responseLink'
										style={{ margin: 0 }}
										data-link={responseURL}
									>
										{responseURL}
									</p>
								</Typography>
								<IconButton
									edge='end'
									id='copyButton'
									data-clipboard-target='#responseLink'
								>
									{<ContentCopyIcon />}
								</IconButton>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					background: '#e6e6e6',
					flexDirection: 'column',
					borderBottomRightRadius: '12px',
					borderTopLeftRadius: { lg: '0' },
					borderTopRightRadius: { lg: '12px' },

					// height: { lg: '100%', md: '100%', sm: '100%', xs: '100%',xl:"100%" },
					// width: { lg: '100%', md: '100%', sm: '100%', xs: '100%', xl: '100%' },
					minWidth: '40%',
					borderBottomLeftRadius: {
						lg: '0',
						md: '0',
						sm: '12px',
						xs: '12px',
					},
				}}
			>
				<Box
					id='con3'
					sx={{
						// height: "100%",
						margin: 'auto 0',
						minWidth: '100%',
					}}
				>
					<Box
						id='con3'
						sx={{
							// height: "100%",

							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
							minWidth: '100%',
						}}
					>
						{responseURL !== '' && url.includes('guest') ? (
							<Alert
								sx={{
									fontSize: { xs: '13px' },
									margin: 'auto 0',
									// minWidth:"95%",
									// width:{lg:"1%",md:"100%",xs:"100%",sm:"100%",xl:"100%"},
									width: { lg: '92.5%', xl: '93.6%', md: '96.8%', sm: '95.4%' },
									borderBottomRightRadius: 0,
									borderBottomLeftRadius: {
										lg: 0,
										md: 0,
										sm: '12px',
										xs: '12px',
									},
								}}
								severity='warning'
							>
								<AlertTitle>{t('warning')}</AlertTitle>
								{t('linkSent')} {t('dataAccesibleFor')}{' '}
								<strong>{t('1Time')}</strong>
							</Alert>
						) : URLRemoved ? (
							<Box
								sx={{
									width: {
										lg: '95%',
										md: '97%',
										xs: '95%',
										sm: '100%',
										xl: '96%',
									},
									background: 'blue',
								}}
							>
								<Alert
									sx={{
										fontSize: { xs: '13px' },
										borderBottomRightRadius: '12px',
										borderBottomLeftRadius: {
											lg: 0,
											md: '12px',
											sm: '12px',
											xs: '12px',
										},
										width: {
											lg: '95%',
											md: '97%',
											xs: '95%',
											sm: '100%',
											xl: '96%',
										},
									}}
									severity='error'
								>
									<AlertTitle>{t('error')}</AlertTitle>
									{t('dataAccessed')}
								</Alert>
							</Box>
						) : (
							<>
								<Box
									// style={{height:"calc(100%-74px)"}}
									sx={{
										width: '90%',
										textAlign: 'center',
										// height:{lg:"calc(100%-74px)",md:"100%",sm:"100%",xs:"100%"},
										paddingTop: '1rem',
									}}
								>
									<FormControl
										variant='outlined'
										sx={{
											width: '100%',
											// height:{lg:"100%",md:"100%",sm:"100%",xs:"100%"},
										}}
									>
										<InputLabel htmlFor='outlined-adornment-text'>
											{t('enterDataToEncrypt')}
										</InputLabel>
										<OutlinedInput
											multiline
											rows={11}
											sx={{
												// height:{lg:"100%",md:"100%",sm:"100%",xs:"100%",xl:"100%"},
												alignItems: { xs: 'center' },
											}}
											name='text'
											variant='outlined'
											onChange={handleOnChange}
											id='outlined-adornment-text'
											value={textToEncrypt}
											label={t('enterDataToEncrypt')}
											// size="large"
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														edge='end'
														id='copyButtonText'
														aria-label='copy content'
														onMouseDown={e => e.preventDefault()}
														data-clipboard-target='#outlined-adornment-text'
													>
														{<ContentCopyIcon />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Box>
								<Box
									sx={{
										textAlign: 'center',
										mt: { lg: 2, md: 1, sm: 1, xs: 1 },
										mb: { lg: 2, md: 1, sm: 1, xs: 2 },
									}}
								>
									<LoadingButton
										loading={loading}
										variant='contained'
										loadingPosition='end'
										endIcon={<ArrowUpwardIcon />}
										onClick={handleEncryptionClick}
										disabled={textToEncrypt === ''}
									>
										{t('submit')}
									</LoadingButton>
								</Box>
							</>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
					}}
				>
					{responseURL !== '' && (url.includes('guest') || URLRemoved) ? (
						<></>
					) : (
						<Box>
							<Alert
								sx={{
									fontSize: { xs: '13px' },
									borderBottomRightRadius: '12px',
									borderBottomLeftRadius: {
										lg: 0,
										md: '12px',
										sm: '12px',
										xs: '12px',
									},
								}}
								severity='warning'
							>
								<AlertTitle>{t('warning')}</AlertTitle>
								{t('dataAvailableAfer1TimeUse')}
							</Alert>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default EncryptText
