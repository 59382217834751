import axios from 'axios'
import { t } from 'i18next'

const UserService = {
  add: (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/add?lang=${t('lang')}`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  update: (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/update`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  delete: (data) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/user/delete?user_id=${data}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  list: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  detail: (data) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/detail?user_id=${data}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  changeStatus: (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/change_active_status?user_id=${data}`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  approve: (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/approve?user_id=${data}&lang=${t('lang')}`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  disableTwoFactorAuth: (id) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/auth_enabled?user_id=${id}`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  }
}

export default UserService