import {
	Menu,
	AppBar,
	Button,
	Toolbar,
	Tooltip,
	MenuItem,
	IconButton,
	Typography,
	FormControl,
	InputLabel,
	Select,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import DeleteDialogBox from '../dialog/DeleteDialogBox'
import TranslateIcon from '@mui/icons-material/Translate'
import AuthService from '../../api-services/auth-service'
import UserService from '../../api-services/user-service'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'

const Navbar = () => {
	let history = useHistory()
	const { url } = useRouteMatch()
	const { t, i18n } = useTranslation()

	const [language, setLanguage] = useState('en')
	const [anchorEl, setAnchorEl] = useState(null)
	const [anchorElNav, setAnchorElNav] = useState(null)
	const [anchorElUser, setAnchorElUser] = useState(null)

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

	const open = Boolean(anchorEl)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const deleteUser = async () => {
		const response = await UserService.delete(localStorage.getItem('user_id'))

		if (!response.error) {
			setOpenDeleteDialog(false)
			localStorage.clear()
			history.push('/')
		}
	}

	const handleLangChange = lang => {
		setLanguage(lang)

		i18n.changeLanguage(lang)
	}

	return (
		<AppBar position='static' sx={{ zIndex: '9999' }}>
			<Toolbar>
				<Typography
					style={{
						cursor:
							localStorage.getItem('role') !== 'admin' ? 'pointer' : 'default',
					}}
					onClick={() => {
						if (localStorage.getItem('role') !== 'admin')
							history.push('/dashboard')
					}}
					className='logo'
					variant='h6'
					noWrap
					component='div'
					sx={{
						mr: 2,
						flexGrow:
							(localStorage.getItem('plan_activated') === 'false' ||
								localStorage.getItem('plan_activated') === null) &&
							(localStorage.getItem('trial') === 'false' ||
								localStorage.getItem('trial') === null)
								? 1
								: 0,
						display: {
							xs: 'none',
							sm: 'none',
							md: 'flex',
						},
					}}
				>
					{/* <img src='/primaryIconLight.png' width='230' /> */}
					<img src='/IdentitySafe-White.webp' width='230' />
				</Typography>
				{localStorage.getItem('plan_activated') === 'false' &&
				localStorage.getItem('trial') === 'false' ? (
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={e => setAnchorElNav(e.currentTarget)}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={() => setAnchorElNav(null)}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							<div>
								<MenuItem>
									<Typography textAlign='center'>
										<a
											rel='noopener'
											target='_blank'
											href='https://identitysafe.de'
											style={{ textDecoration: 'none', color: '#000000' }}
										>
											{t('aboutIdentitySafe')}
										</a>
									</Typography>
								</MenuItem>
							</div>
						</Menu>
					</Box>
				) : (
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={e => setAnchorElNav(e.currentTarget)}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={() => setAnchorElNav(null)}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{localStorage.getItem('role') === 'admin' ? (
								<div>
									<MenuItem onClick={() => history.push('/admin-dashboard')}>
										<Typography textAlign='center'>Manage Users</Typography>
									</MenuItem>
									<MenuItem onClick={() => history.push('/plans')}>
										<Typography textAlign='center'>Manage Plans</Typography>
									</MenuItem>
								</div>
							) : localStorage.getItem('plan_activated') === 'true' ||
							  localStorage.getItem('trial') === 'true' ? (
								<div>
									<MenuItem
										onClick={() => {
											if (url.includes('guest'))
												history.push('/guest-encrypt-file')
											else history.push('/encrypt-file')
										}}
									>
										<Typography textAlign='center'>
											{t('encryptFile')}
										</Typography>
									</MenuItem>
									<MenuItem
										onClick={() => {
											if (url.includes('guest'))
												history.push('/guest-encrypt-text')
											else history.push('/encrypt-text')
										}}
									>
										<Typography textAlign='center'>
											{t('encryptText')}
										</Typography>
									</MenuItem>
									<MenuItem onClick={() => history.push('/generate-url')}>
										<Typography textAlign='center'>
											{t('generateURL')}
										</Typography>
									</MenuItem>
									<MenuItem>
										<Typography textAlign='center'>
											<a
												href='https://identitysafe.de'
												target='_blank'
												rel='noopener'
												style={{ textDecoration: 'none', color: '#000000' }}
											>
												{t('aboutIdentitySafe')}
											</a>
										</Typography>
									</MenuItem>
								</div>
							) : (
								<div>
									<MenuItem>
										<Typography textAlign='center'>
											<a
												href='https://identitysafe.de'
												target='_blank'
												rel='noopener'
												style={{ textDecoration: 'none', color: '#000000' }}
											>
												{t('aboutIdentitySafe')}
											</a>
										</Typography>
									</MenuItem>
								</div>
							)}
						</Menu>
					</Box>
				)}
				{localStorage.getItem('plan_activated') === 'false' &&
				localStorage.getItem('trial') === 'false' ? (
					<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
						<Typography
							variant='h6'
							noWrap
							component='div'
							sx={{
								mr: 2,
								flexGrow: 1,
								display: { xs: 'none', md: 'none', lg: 'none' },
							}}
						>
							<img src='/primaryIcon.png' />
						</Typography>
						<Button>
							<a
								rel='noopener'
								target='_blank'
								href='https://identitysafe.de'
								style={{ textDecoration: 'none', color: '#ffffff' }}
							>
								{t('aboutIdentitySafe')}
							</a>
						</Button>
					</Box>
				) : (
					<Typography
						variant='h6'
						noWrap
						component='div'
						sx={{
							mr: 2,
							flexGrow: 1,
							display: { xs: 'none', md: 'none', lg: 'none' },
						}}
					>
						<img src='/primaryIcon.png' />
					</Typography>
				)}
				{localStorage.getItem('role') === 'admin' ? (
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Button
							color='inherit'
							onClick={() => history.push('/manage-plans')}
						>
							{t('managePlans')}
						</Button>
						<Button
							color='inherit'
							onClick={() => history.push('/admin-dashboard')}
						>
							{t('manageUsers')}
						</Button>
					</Box>
				) : localStorage.getItem('plan_activated') === 'true' ||
				  localStorage.getItem('trial') === 'true' ? (
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Button
							color='inherit'
							onClick={() => {
								if (url.includes('guest')) history.push('/guest-encrypt-file')
								else history.push('/encrypt-file')
							}}
						>
							{t('encryptFile')}
						</Button>
						<Button
							color='inherit'
							onClick={() => {
								if (url.includes('guest')) history.push('/guest-encrypt-text')
								else history.push('/encrypt-text')
							}}
						>
							{t('encryptText')}
						</Button>
						{url.includes('guest') ? (
							<></>
						) : (
							<Button
								color='inherit'
								onClick={() => history.push('/generate-url')}
							>
								{t('generateURL')}
							</Button>
						)}
						<Button>
							<a
								style={{ textDecoration: 'none', color: '#ffffff' }}
								target='_blank'
								href='https://identitysafe.de'
								rel='noopener'
							>
								{t('aboutIdentitySafe')}
							</a>
						</Button>
					</Box>
				) : (
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{/* <Button>
							<a
								rel='noopener'
								target='_blank'
								href='https://identitysafe.de'
								style={{ textDecoration: 'none', color: '#ffffff' }}
							>
								{t('aboutIdentitySafe')}
							</a>
						</Button> */}
					</Box>
				)}
				<IconButton
					color='inherit'
					id='long-button'
					aria-label='more'
					aria-haspopup='true'
					onClick={handleClick}
					aria-expanded={open ? 'true' : undefined}
					aria-controls={open ? 'long-menu' : undefined}
				>
					<TranslateIcon />
				</IconButton>
				<Menu
					open={open}
					id='long-menu'
					anchorEl={anchorEl}
					onClose={handleClose}
					PaperProps={{
						style: {
							width: '10ch',
							maxHeight: 48 * 4.5,
						},
					}}
					MenuListProps={{
						'aria-labelledby': 'long-button',
					}}
				>
					<MenuItem onClick={() => handleLangChange('en')}>{t('en')}</MenuItem>
					<MenuItem onClick={() => handleLangChange('de')}>{t('de')}</MenuItem>
				</Menu>
				{localStorage.getItem('email') ? (
					<Box sx={{ float: 'right' }}>
						<Tooltip title='Open settings'>
							<Button
								color='inherit'
								onClick={e => {
									setAnchorElUser(e.currentTarget)
								}}
							>
								{localStorage.getItem('email')}
							</Button>
						</Tooltip>
						<Menu
							keepMounted
							id='menu-appbar'
							sx={{ mt: '45px' }}
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={() => setAnchorElUser(null)}
						>
							<MenuItem
								onClick={() =>
									history.push(`/profile/${localStorage.getItem('user_id')}`)
								}
							>
								<Typography textAlign='center'>{t('yourProfile')}</Typography>
							</MenuItem>
							<MenuItem onClick={() => history.push('/change-password')}>
								<Typography textAlign='center'>
									{t('changePassword')}
								</Typography>
							</MenuItem>

							{localStorage.getItem('role') !== 'admin' ? (
								<MenuItem onClick={() => setOpenDeleteDialog(true)}>
									<Typography
										color={'error'}
										textAlign='center'
										fontWeight={'bold'}
									>
										{t('deleteAccount')}
									</Typography>
								</MenuItem>
							) : (
								<div></div>
							)}

							<MenuItem onClick={() => history.push('/activate-2fa')}>
								<Typography textAlign='center'>
									{t('protectAccount')}
								</Typography>
							</MenuItem>

							{localStorage.getItem('plan_activated') === 'true' ? (
								<MenuItem onClick={() => history.push(`/plans/unsubscribe`)}>
									<Typography textAlign='center'>
										Unsubscribe from Plan
									</Typography>
								</MenuItem>
							) : (
								<div></div>
							)}
							{(localStorage.getItem('plan_activated') === 'false' ||
								localStorage.getItem('trial') === 'true') &&
							localStorage.getItem('role') !== 'admin' ? (
								<MenuItem onClick={() => history.push(`/plans`)}>
									<Typography textAlign='center'>{t('subToPlan')}</Typography>
								</MenuItem>
							) : (
								<div></div>
							)}

							<MenuItem onClick={async () => await AuthService.logout(history)}>
								<Typography textAlign='center'>{t('logout')}</Typography>
							</MenuItem>
						</Menu>
					</Box>
				) : (
					<Box sx={{ float: 'right' }}>
						<Button color='inherit' onClick={() => history.push('/login')}>
							{t('login')}
						</Button>
					</Box>
				)}
			</Toolbar>
			<DeleteDialogBox
				usageFlag='user'
				open={openDeleteDialog}
				deleteUser={deleteUser}
				handleClose={() => setOpenDeleteDialog(false)}
			/>
		</AppBar>
	)
}

export default Navbar
