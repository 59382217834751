import axios from 'axios'
import { t } from 'i18next'

const AuthService = {
	register: data => {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/user/register?lang=${t('lang')}`, data)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	verifyOTP: data => {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/user/otp_verification`, data)
			.then(({ data }) => {
				if (!data.error) setAccessToken(data)
				return data
			})
			.catch(err => {
				throw err
			})
	},
	resendVerificationLink: data => {
		return axios
			.get(
				`${process.env.REACT_APP_API_URL}/user/resend_verification_link?user_id=${data}&lang=${t('lang')}`
			)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	enableTwoFactorAuth: () => {
		return axios
			.patch(
				`${
					process.env.REACT_APP_API_URL
				}/user/auth_enabled?user_id=${localStorage.getItem('user_id')}&lang=${t('lang')}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	forgetPassword: data => {
		return axios
			.post(
				`${process.env.REACT_APP_API_URL}/user/forget_password?email=${data}&lang=${t('lang')}`
			)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	verifyEmail: data => {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/user/verify_user?token=${data}&lang=${t('lang')}`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	login: data => {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/user/login`, data)
			.then(({ data }) => {
				if (data.error === false) {
					setLocalStorage(data)
					return data
				} else {
					return data
				}
			})
			.catch(err => {
				throw err
			})
	},
	changePassword: data => {
		return axios
			.patch(`${process.env.REACT_APP_API_URL}/user/change_password`, data, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			})
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				throw err
			})
	},
	logout: history => {
		return axios
			.delete(`${process.env.REACT_APP_API_URL}/user/logout`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			})
			.then(({ data }) => {
				if (data.error === false) {
					localStorage.clear()
					history.push('/')
					return data
				} else {
					return data
				}
			})
			.catch(err => {
				throw err
			})
	},
}

const setAccessToken = data => {
	localStorage.setItem('authenticated', true)
	localStorage.setItem('token', data.access_token)
}

const setLocalStorage = data => {
	if (data.access_token) localStorage.setItem('token', data.access_token)
	if (data.role_name === 'admin') {
		localStorage.setItem('user_id', data.id)
		localStorage.setItem('email', data.email)
		localStorage.setItem('role', data.role_name)
		localStorage.setItem('isActive', data.isActive)
		localStorage.setItem('isApproved', data.isApproved)
		localStorage.setItem('isVerified', data.isVerified)
		localStorage.setItem('authEnabled', data.auth_enabled)
	} else {
		localStorage.setItem('user_id', data.id)
		localStorage.setItem('email', data.email)
		localStorage.setItem('trial', data.trial)
		localStorage.setItem('role', data.role_name)
		localStorage.setItem('isActive', data.isActive)
		localStorage.setItem('isApproved', data.isApproved)
		localStorage.setItem('isVerified', data.isVerified)
		localStorage.setItem('authEnabled', data.auth_enabled)
		localStorage.setItem('plan_activated', data.plan_activated)
		localStorage.setItem('subscription_id', data.subscription_id)
	}
}

export default AuthService
