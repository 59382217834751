import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import { withRouter, useRouteMatch } from 'react-router-dom'

const Layout = ({ children, location, ...rest }) => {
  const { url } = useRouteMatch()

  return (
    <>
      {url.includes('guest') && url.split('/').length < 3 ? <Navbar /> : <></>}
      <>{children}</>

      {url === '/signup' ? (
        <></>
      ) : url === '/' ? (
        <></>
      ) : url === '/forget-password' ? (
        <></>
      ) : url === '/auth-2fa' ? (
        <></>
      ) : (
        <Box
          sx={{
            left: '0',
            zIndex: 100,
            bottom: '0',
            height: '40px',
            position: 'fixed',
            paddingTop: '3px',
            overflowX: 'hidden',
            overflowY: 'hidden',
            paddingBottom: '3px',
            background: '#1976d2',
            width: { lg: '100%', md: '100%', sm: '100%', xl: '100%', xs: '100%' },
          }}
        >
          <Footer />
        </Box>
      )}
    </>
  )
}

Layout.propType = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(Layout)
