import React from 'react'
import { Alert, Snackbar } from '@mui/material'

const SnackBar = ({ alertOpen, handleClose, message, severity }) => {
	return (
		<Snackbar
			open={alertOpen}
			onClose={handleClose}
			autoHideDuration={4000}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default SnackBar
