import {
	Card,
	Alert,
	Paper,
	Snackbar,
	Typography,
	CardContent,
	CardActions,
	LinearProgress,
	CardMedia,
} from '@mui/material'
import ReactDOM from 'react-dom'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import PlanService from '../../api-services/plan-service'
import SubscriptionService from '../../api-services/subscription-service'

const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })

const Plans = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [plans, setPlans] = useState('')
	const [loading, setLoading] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)

	const history = useHistory()

	const handleClose = () => {
		setAlertOpen(false)
	}

	const fetchPlans = async () => {
		setLoading(true)

		const response = await PlanService.list()

		if (!response.error) {
			setLoading(false)

			setPlans(response.data)
		} else {
			setLoading(false)
			setError(response.msg)
		}
	}

	useEffect(() => {
		fetchPlans()
	}, [])

	return (
		<>
			{localStorage.getItem('authenticated') === 'true' ? (
				localStorage.getItem('plan_activated') === 'true' ? (
					<Redirect
						to={{
							pathname: '/dashboard',
						}}
					/>
				) : (
					<Box
						sx={{
							minHeight: '82vh',
							marginBottom: '1rem',
							marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
							marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
						}}
					>
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								marginTop: '2rem',
								borderRadius: '15px',
								alignItems: 'center',
								justifyContent: 'center',
								// marginTop: { lg: '2%', md: '4%', sm: '40%', xs: '20%' },
								// height: { lg: "80vh", md: '80vh', sm: '80vh', xl: '90vh' },

								flexDirection: {
									lg: 'row',
									md: 'row',
									sm: 'column',
									xs: 'column',
								},
							}}
						>
							{loading ? (
								<Box
									sx={{
										width: '100%',
									}}
								>
									<LinearProgress />
								</Box>
							) : plans.length === 0 ? (
								<Typography
									variant='h4'
									sx={{ color: '#1976D2', fontWeight: 'bold' }}
								>
									No Plans Found
								</Typography>
							) : (
								plans
									.filter(plan => plan.price !== 0)
									.filter(plan => plan.status === 'ACTIVE')
									.map((plan, i) => (
										<Paper
											key={i}
											elevation={10}
											sx={{
												width: '65%',
												minHeight: '75vh',
												marginRight: '15px',
												textAlign: 'center',
												// height: {lg:"75vh",md:"75vh",sm:"75vh",xl:"75vh"},

												marginBottom: {
													lg: '2rem',
													md: '2rem',
													sm: '2rem',
													xs: '2rem',
												},
											}}
										>
											<Card
												variant='outlined'
												sx={{
													width: '100%',
													display: 'flex',
													margin: '0 auto',
													// height: '100%',
													minHeight: '75vh',
													marginRight: '5px',
													textAlign: 'center',
													flexDirection: 'column',
												}}
											>
												<CardContent
													sx={{
														display: 'flex',
														alignItems: 'center',
														flexDirection: 'column',
														justifyContent: 'center',
													}}
												>
													<Box
														sx={{
															width: '80%',
															height: '50%',
															marginTop: '3rem',
														}}
													>
														<CardMedia
															component='img'
															image='/primaryIcon.png'
														/>
													</Box>
													<Typography
														variant='h4'
														sx={{
															width: '97%',
															marginTop: '2rem',
															fontWeight: 'bold',
														}}
													>
                            IdentitySafe
														{/* {plan.plan_name.charAt(0).toUpperCase() +
															plan.plan_name.slice(1)} */}
													</Typography>
													<Typography
														variant='h4'
														sx={{
															mb: 0.5,
															marginTop: '3rem',
															fontWeight: 'bold',
														}}
													>
														{plan.price} Euro
														<br />
														{plan.duration === 30 ||
														plan.duration === 31 ||
														plan.duration === 28
															? '/month'
															: plan.duration === 365
															? '/year'
															: plan.duration === 1
															? '/day'
															: ''}
														<Typography variant='h6' color='text.secondary'>
															{t('perYearInclVAT')}
														</Typography>
													</Typography>
													<Typography
														variant='body1'
														component='div'
														sx={{ marginTop: '4rem' }}
													>
														{t('planDescription')}
													</Typography>
													<Typography
														variant='body1'
														component='div'
														sx={{ marginTop: '1rem' }}
													>
														{t('invoiceInSeperateMail')}
													</Typography>
												</CardContent>
												<CardActions
													id={i}
													sx={{
														margin: 'auto',

														//  marginTop:{lg:"3rem",sm:"1rem",md:"2rem",xs:"1rem",xl:"8rem"},
													}}
												>
													<PayPalButton
														createSubscription={(data, actions) => {
															return actions.subscription.create({
																plan_id: plan.paypal_plan_id,
															})
														}}
														onApprove={async (data, actions) => {
															const response =
																await SubscriptionService.subscribe({
																	subscriptionId: data.subscriptionID,
																	planId: plan.id,
																})

															if (!response.error) {
																localStorage.setItem(
																	'subscription_id',
																	data.subscriptionID
																)
																localStorage.setItem('plan_activated', true)
																localStorage.setItem('plan_id', plan.id)
																localStorage.setItem('trial', false)

																history.push('/dashboard')
															}
														}}
													/>
												</CardActions>
											</Card>
										</Paper>
									))
							)}
						</Box>
						<Snackbar
							open={alertOpen}
							onClose={handleClose}
							autoHideDuration={6000}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						>
							<Alert
								severity='error'
								onClose={handleClose}
								sx={{ width: '100%' }}
							>
								{error}
							</Alert>
						</Snackbar>
					</Box>
				)
			) : (
				<Redirect
					pathname={{
						to: '/',
					}}
				/>
			)}
		</>
	)
}

export default Plans
