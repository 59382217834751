import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const { t, i18n } = useTranslation()

	return (
		<Box
			sx={{
				margin: '5px',
				display: 'flex',
				overflow: 'hidden',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<a
				target='_blank'
				rel='noopener noreferrer'
				href={`https://www.identitysafe.de/disclaimer/${
					t('lang') === 'en' ? '?lang=en' : ''
				}`}
				style={{ textDecoration: 'none', color: 'white', margin: '0 1rem' }}
			>
				{t('disclaimer')}
			</a>
			<a
				target='_blank'
				rel='noopener noreferrer'
				href={`https://www.identitysafe.de/${
					t('lang') === 'en' ? 'privacy-policy' : 'datenschutz'
				}/${t('lang') === 'en' ? '?lang=en' : ''}`}
				style={{ textDecoration: 'none', color: 'white', margin: '0 1rem' }}
			>
				{t('privacyPolicy')}
			</a>
		</Box>
	)
}

export default Footer
