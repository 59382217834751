import {
	Box,
	Alert,
	Snackbar,
	Typography,
	InputLabel,
	FormControl,
	OutlinedInput,
	LinearProgress,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import React, { useState, useEffect } from 'react'
import UserService from '../../api-services/user-service'
import { useTranslation } from 'react-i18next'

const User = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()

	const [user, setUser] = useState({})
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)
	const [updating, setUpdating] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [editUserForm, setEditUserForm] = useState({ fullname: '' })

	const handleOnChange = e => {
		const { name, value } = e.target

		setEditUserForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleEditUser = async e => {
		e.preventDefault()

		setUpdating(true)

		const editUserFormData = new FormData()

		editUserFormData.append('fullname', editUserForm.fullname)
		editUserFormData.append('user_id', user.id)

		const response = await UserService.update(editUserFormData)

		if (!response.error) {
			setUpdating(false)

			const response = await UserService.detail(id)

			if (!response.error) {
				setLoading(false)
				let tempObj = { fullname: '' }

				setUser(response.data)

				tempObj.fullname =
					response.data.fullname === null ? '' : response.data.fullname
				setEditUserForm(tempObj)
			} else {
				setLoading(false)
				setError(response.error)
			}
		} else {
			setUpdating(false)
			setError(response.error)
			setAlertOpen(true)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			const response = await UserService.detail(id)

			if (!response.error) {
				setLoading(false)
				let tempObj = { fullname: '' }

				tempObj.fullname =
					response.data.fullname === null ? '' : response.data.fullname
				setEditUserForm(tempObj)
				setUser(response.data)
			} else {
				setLoading(false)
				setError(response.error)
			}
		}

		fetchData()
	}, [])

	return (
		<>
			{loading ? (
				<Box
					sx={{
						width: '90%',

						marginTop: '2rem',
						marginLeft: { lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem' },
						overflow: 'hidden',
						scrollbarWidth: 'none',
						'&::-webkit-scrollbar': {
							display: 'none',
							overflow: 'hidden',
						},
					}}
				>
					<LinearProgress
						sx={{
							overflow: 'hidden',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollbarWidth: 'none',
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
						marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							marginTop: '2%',
							alignItems: 'center',
							border:'1px solid #3a3a3a',
							borderRadius: '12px',
							height: { lg: '80vh', md: '80vh', sm: '80vh', xs: '80vh' },
							flexDirection: {
								lg: 'row',
								md: 'column',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						<Box
							sx={{
								// background: '#b3e6ff',
								borderTopLeftRadius: '12px',
								borderBottomLeftRadius: { lg: '12px' },
								height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
								width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
								borderTopRightRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
							}}
						/>
						<Box
							sx={{
								background: '#e6e6e6',
								borderBottomRightRadius: '12px',
								height: { lg: '100%', md: '70%' },
								borderTopRightRadius: { lg: '12px' },
								width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
								borderBottomLeftRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
							}}
						>
							<Box
								sx={{
									mx: 8,
									height: '70%',
									marginTop: { lg: '25%' },
								}}
							>
								<Box>
									<Typography
										sx={{ fontSize: '15px' }}
										variant='overline'
										display='block'
									>
										{t('yourProfile')}
										<br />
									</Typography>
								</Box>
								<form onSubmit={handleEditUser}>
									<Box
										sx={{
											textAlign: 'center',
										}}
									>
										<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
											<InputLabel htmlFor='outlined-adornment-fullname'>
												{t('fullname')}
											</InputLabel>
											<OutlinedInput
												type='text'
												name='fullname'
												variant='outlined'
												label={t('fullname')}
												onChange={handleOnChange}
												id='outlined-adornment-fullname'
												value={editUserForm.fullname || ''}
											/>
										</FormControl>
									</Box>
									<Box
										sx={{
											textAlign: 'center',
										}}
									>
										<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
											<InputLabel htmlFor='outlined-adornment-email'>
												{t('email')}
											</InputLabel>
											<OutlinedInput
												disabled
												readOnly
												type='email'
												name='email'
												label={t('email')}
												variant='outlined'
												value={user.email || ''}
												id='outlined-adornment-email'
											/>
										</FormControl>
									</Box>

									<Box
										sx={{
											textAlign: 'center',
										}}
									>
										<FormControl fullWidth sx={{ mt: 1 }}>
											<InputLabel htmlFor='outlined-adornment-role'>
												{t('role')}
											</InputLabel>
											<OutlinedInput
												disabled
												readOnly
												type='text'
												name='role'
												label={t('role')}
												variant='outlined'
												value={user.role_name ? t(user.role_name) : ''}
												id='outlined-adornment-role'
											/>
										</FormControl>
									</Box>

									<Box
										sx={{
											textAlign: 'center',
											mt: 2,
                      mb:{sm:1,lg:0,xl:0,md:0,xs:1}
                      
										}}
									>
										<LoadingButton
											type='submit'
											loading={updating}
											variant='contained'
											loadingPosition='start'
											startIcon={<EditIcon />}
											disabled={editUserForm.fullname === user.fullname}
										>
											{t('editInfo')}
										</LoadingButton>
									</Box>
								</form>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			<Snackbar
				open={alertOpen}
				onClose={() => setAlertOpen(false)}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={() => setAlertOpen(false)}
					sx={{ width: '100%' }}
					severity={error === '' ? 'success' : 'error'}
				>
					{error === '' ? 'User Added Successfully' : error}
				</Alert>
			</Snackbar>
		</>
	)
}

export default User
