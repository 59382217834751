import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import AddUser from '../../components/user/AddUser'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { GridActionsCellItem } from '@mui/x-data-grid'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import UserService from '../../api-services/user-service'
import RoleService from '../../api-services/role-service'
import DataTable from '../../components/dataTable/DataTable'
import { Alert, Box, Container, Snackbar, Typography } from '@mui/material'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import EditDialogBox from '../../components/dialog/EditDialogBox'
import DeleteDialogBox from '../../components/dialog/DeleteDialogBox'
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1'

const AdminDashboard = () => {
	const { t, i18n } = useTranslation()

	const [id, setId] = useState('')
	const [error, setError] = useState('')
	const [users, setUsers] = useState([])
	const [roles, setRoles] = useState([])
	const [loading, setLoading] = useState(true)
	const [updating, setUpdating] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [editUserForm, setEditUserForm] = useState({
		fullname: '',
		user_id: '',
		trialDuration: '',
	})

	const columns = [
		{ field: 'col1', headerName: t('email'), width: 200 },
		{ field: 'col2', headerName: t('fullname'), width: 150 },
		{ field: 'col3', headerName: t('planExpiration'), width: 130 },
		{ field: 'col4', headerName: t('accountStatus'), width: 125 },
		{ field: 'col5', headerName: t('emailStatus'), width: 110 },
		{ field: 'col6', headerName: t('approvalStatus'), width: 130 },
		{ field: 'col7', headerName: t('2faStatus'), width: 110 },
		{
			field: 'actions',
			type: 'actions',
			headerName: t('actions'),
			width: 100,
			getActions: params => [
				<GridActionsCellItem
					icon={<EditIcon color='primary' />}
					label={t('edit')}
					onClick={() => {
						let tempObj = {
							fullname: params.row.col2,
							user_id: params.id,
							plan_activated: params.plan_activated,
							duration: params.duration,
						}

						setEditUserForm(tempObj)
						setOpenEditDialog(true)
					}}
					showInMenu
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon color='error' />}
					label={t('delete')}
					onClick={() => {
						setId(params.id)
						setOpenDeleteDialog(true)
					}}
					showInMenu
				/>,
				<GridActionsCellItem
					icon={
						params.row.col4 === 'Suspended' ? (
							<PersonAddAlt1Icon color='success' />
						) : (
							<PersonRemoveAlt1Icon color='error' />
						)
					}
					label={params.row.col4 === 'Suspended' ? t('activate') : t('suspend')}
					onClick={async () => {
						setLoading(true)
						const response = await UserService.changeStatus(params.id)

						// setLoading(false)
						if (!response.error) {
							setUsers([])
							const userServiceResponse = await UserService.list()

							let tempArr = []

							if (userServiceResponse.data !== undefined) {
								for (let i = 0; i < userServiceResponse.data.length; i++) {
									if (
										userServiceResponse.data[i].id !==
										localStorage.getItem('user_id')
									)
										tempArr.push({
											id: userServiceResponse.data[i].id,
											col1: userServiceResponse.data[i].email,
											col2: userServiceResponse.data[i].fullname,
											col3: moment(
												userServiceResponse.data[i].plan_expiry_date
											).format('MMM Do YYYY'),
											col4: userServiceResponse.data[i].isActive
												? 'Active'
												: 'Suspended',
											col5: userServiceResponse.data[i].isVerified
												? 'Verified'
												: 'Not Verified',
											col6: userServiceResponse.data[i].isApproved
												? 'Approved'
												: 'Not Approved',
											col7: userServiceResponse.data[i].auth_enabled
												? 'Enabled'
												: 'Disabled',
										})
								}
								setLoading(false)
								setUsers(tempArr)
							}
						} else {
							setError('Something went wrong...')
							setAlertOpen(true)
						}
					}}
					showInMenu
				/>,
				<GridActionsCellItem
					icon={<HowToRegIcon color='success' />}
					label={
						params.row.col6 === 'Not Approved' ? t('approve') : t('approved')
					}
					onClick={async () => {
						setLoading(true)
						const response = await UserService.approve(params.id)

						if (!response.error) {
							setUsers([])

							const userServiceResponse = await UserService.list()

							let tempArr = []

							if (userServiceResponse.data !== undefined) {
								for (let i = 0; i < userServiceResponse.data.length; i++) {
									if (
										userServiceResponse.data[i].id !==
										localStorage.getItem('user_id')
									)
										tempArr.push({
											id: userServiceResponse.data[i].id,
											col1: userServiceResponse.data[i].email,
											col2: userServiceResponse.data[i].fullname,
											col3: moment(
												userServiceResponse.data[i].plan_expiry_date
											).format('MMM Do YYYY'),
											col4: userServiceResponse.data[i].isActive
												? 'Active'
												: 'Suspended',
											col5: userServiceResponse.data[i].isVerified
												? 'Verified'
												: 'Not Verified',
											col6: userServiceResponse.data[i].isApproved
												? 'Approved'
												: 'Not Approved',
											col7: userServiceResponse.data[i].auth_enabled
												? 'Enabled'
												: 'Disabled',
										})
								}
								setUsers(tempArr)
								setLoading(false)
							}
						}
					}}
					showInMenu
					disabled={params.row.col6 !== 'Not Approved'}
				/>,
				<GridActionsCellItem
					icon={<QrCode2Icon color={'error'} />}
					label={params.row.col7 === 'Enabled' ? t('disable') : t('disabled')}
					onClick={async () => {
						setLoading(true)
						const response = await UserService.disableTwoFactorAuth(params.id)

						if (!response.error) {
							setUsers([])
							const userServiceResponse = await UserService.list()

							let tempArr = []

							if (userServiceResponse.data !== undefined) {
								for (let i = 0; i < userServiceResponse.data.length; i++) {
									if (
										userServiceResponse.data[i].id !==
										localStorage.getItem('user_id')
									)
										tempArr.push({
											id: userServiceResponse.data[i].id,
											col1: userServiceResponse.data[i].email,
											col2: userServiceResponse.data[i].fullname,
											col3: moment(
												userServiceResponse.data[i].plan_expiry_date
											).format('MMM Do YYYY'),
											col4: userServiceResponse.data[i].isActive
												? 'Active'
												: 'Suspended',
											col5: userServiceResponse.data[i].isVerified
												? 'Verified'
												: 'Not Verified',
											col6: userServiceResponse.data[i].isApproved
												? 'Approved'
												: 'Not Approved',
											col7: userServiceResponse.data[i].auth_enabled
												? 'Enabled'
												: 'Disabled',
										})
								}
								setUsers(tempArr)
								setLoading(false)
							}
						}
					}}
					showInMenu
					disabled={params.row.col7 === 'Disabled'}
				/>,
			],
		},
	]

	const handleOnChange = e => {
		const { name, value } = e.target

		setEditUserForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleClose = () => {
		setAlertOpen(false)
	}

	const deleteUser = async () => {
		const response = await UserService.delete(id)

		if (!response.error) {
			const response = await UserService.list()

			setLoading(false)
			if (!response.error) {
				let tempArr = []

				if (response.data !== undefined) {
					for (let i = 0; i < response.data.length; i++) {
						if (response.data[i].id !== localStorage.getItem('user_id'))
							if (response.data[i].id !== localStorage.getItem('user_id'))
								tempArr.push({
									id: response.data[i].id,
									col1: response.data[i].email,
									col2: response.data[i].fullname,
									col3: moment(response.data[i].plan_expiry_date).format(
										'MMM Do YYYY'
									),
									col4: response.data[i].isActive ? 'Active' : 'Suspended',
									col5: response.data[i].isVerified
										? 'Verified'
										: 'Not Verified',
									col6: response.data[i].isApproved
										? 'Approved'
										: 'Not Approved',
									col7: response.data[i].auth_enabled ? 'Enabled' : 'Disabled',
								})
					}
					setUsers(tempArr)
					setOpenDeleteDialog(false)
				}
			}
		}
	}

	const handleEditUser = async () => {
		setUpdating(true)

		const editUserFormData = new FormData()

		editUserFormData.append('user_id', editUserForm.user_id)
		editUserFormData.append('fullname', editUserForm.fullname)
		editUserFormData.append('duration', editUserForm.trialDuration)

		const response = await UserService.update(editUserFormData)

		if (!response.error) {
			setLoading(true)
			setUpdating(false)
			setOpenEditDialog(false)

			const response = await UserService.list()

			if (!response.error) {
				setLoading(false)
				let tempArr = []

				if (response.data !== undefined) {
					for (let i = 0; i < response.data.length; i++) {
						if (response.data[i].id !== localStorage.getItem('user_id'))
							tempArr.push({
								id: response.data[i].id,
								col1: response.data[i].email,
								col2: response.data[i].fullname,
								col3: moment(response.data[i].plan_expiry_date).format(
									'MMM Do YYYY'
								),
								col4: response.data[i].isActive ? 'Active' : 'Suspended',
								col5: response.data[i].isVerified ? 'Verified' : 'Not Verified',
								col6: response.data[i].isApproved ? 'Approved' : 'Not Approved',
								col7: response.data[i].auth_enabled ? 'Enabled' : 'Disabled',
							})
					}
					setUsers(tempArr)
				}
			}
		}
	}

	const fetchDataList = async () => {
		const response = await RoleService.list()

		if (!response.error) {
			setRoles(response.data)

			const userServiceResponse = await UserService.list()

			if (!userServiceResponse.error) {
				setLoading(false)

				let tempArr = []

				if (userServiceResponse.data !== undefined) {
					for (let i = 0; i < userServiceResponse.data.length; i++) {
						if (
							userServiceResponse.data[i].id !== localStorage.getItem('user_id')
						)
							tempArr.push({
								id: userServiceResponse.data[i].id,
								col1: userServiceResponse.data[i].email,
								col2: userServiceResponse.data[i].fullname,
								col3: moment(
									userServiceResponse.data[i].plan_expiry_date
								).format('MMM Do YYYY'),
								col4: userServiceResponse.data[i].isActive
									? 'Active'
									: 'Suspended',
								col5: userServiceResponse.data[i].isVerified
									? 'Verified'
									: 'Not Verified',
								col6: userServiceResponse.data[i].isApproved
									? 'Approved'
									: 'Not Approved',
								col7: userServiceResponse.data[i].auth_enabled
									? 'Enabled'
									: 'Disabled',
							})
					}
					setUsers(tempArr)
				}
			} else {
				setLoading(false)
				setError('Something went wrong...')
				setAlertOpen(true)
			}
		} else {
			setLoading(false)
			setError('Something went wrong...')
			setAlertOpen(true)
		}
	}

	useEffect(() => {
		fetchDataList()
	}, [])

	return (
		<>
			{localStorage.getItem('role') === 'admin' ? (
				<>
					<Container
						maxWidth='xl'
						sx={{
							display: 'flex',
							flexDirection: {
								lg: 'row',
								md: 'row',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								marginTop: '2%',
								flexDirection: {
									lg: 'column',
									md: 'column',
									sm: 'column',
									xs: 'column',
								},
								'& .success': {
									color: '#29a329',
									fontWeight: 'bold',
								},
								'& .danger': {
									color: '#ff3333',
									fontWeight: 'bold',
								},
								'& .warning': {
									color: '#ffa31a',
									fontWeight: 'bold',
								},
								borderRadius: '15px',
								width: { lg: '75vw', md: '60vw', sm: '100%' },
								height: { lg: '80vh', md: '70vh', sm: '70vh', xs: '70vh' },
							}}
						>
							<DataTable
								rows={users}
								columns={columns}
								loading={loading}
								getCellClassName={params => {
									if (params.field === 'col4')
										return params.value === 'Active' ? 'success' : 'danger'
									else if (params.field === 'col5')
										return params.value === 'Verified' ? 'success' : 'danger'
									else if (params.field === 'col6')
										return params.value === 'Approved' ? 'success' : 'danger'
									else if (params.field === 'col7')
										return params.value === 'Enabled' ? 'success' : 'warning'
								}}
							/>
						</Box>
						<Box
							sx={{
								display: 'flex',
								marginTop: '3%',
								flexDirection: {
									lg: 'row',
									md: 'row',
									sm: 'column',
									xs: 'column',
								},
								alignItems: 'center',
								borderRadius: '15px',
								padding: { lg: '10px', md: '10px' },
								width: { lg: '25vw', md: '40vw', sm: '100%' },
								height: { lg: '70vh', md: '70vh', sm: '70vh', xs: '70vh' },
							}}
						>
							<AddUser roles={roles} setUsers={setUsers} />
						</Box>
					</Container>
					<Snackbar
						open={alertOpen}
						onClose={handleClose}
						autoHideDuration={3000}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							onClose={handleClose}
							sx={{ width: '100%' }}
							severity={error === '' ? 'success' : 'error'}
						>
							{error === '' ? t('userAddSuccess') : error}
						</Alert>
					</Snackbar>
					<DeleteDialogBox
						usageFlag='user'
						open={openDeleteDialog}
						deleteUser={deleteUser}
						handleClose={() => setOpenDeleteDialog(false)}
					/>
					<EditDialogBox
						updating={updating}
						open={openEditDialog}
						editUser={handleEditUser}
						editUserForm={editUserForm}
						handleOnChange={handleOnChange}
						handleClose={() => setOpenEditDialog(false)}
					/>
				</>
			) : (
				<Redirect to='/' />
			)}
		</>
	)
}

export default AdminDashboard
