import React from 'react'
import { DataGrid } from '@mui/x-data-grid'

const DataTable = ({ columns, rows, loading, getCellClassName }) => {
	return (
		<DataGrid
			rows={rows}
			loading={loading}
			columns={columns}
			disableSelectionOnClick
			getCellClassName={getCellClassName}
		/>
	)
}

export default DataTable
