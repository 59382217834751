import axios from 'axios'

const RoleService = {
  list: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/roles/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  }
}

export default RoleService