import { Container, Typography, Grid } from '@mui/material'
import React from 'react'
import { Box } from '@mui/system'
import ClipboardJS from 'clipboard'
import GenerateURL from '../GenerateURL'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import EncryptFile from '../../components/encryption/file/EncryptFile'
import EncryptText from '../../components/encryption/text/EncryptText'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

const Dashboard = () => {
	new ClipboardJS('#copyButton')
	new ClipboardJS('#copyButtonText')

	const history = useHistory()
	const { url } = useRouteMatch()
	const { t, i18n } = useTranslation()

	if (
		(localStorage.getItem('plan_activated') === null ||
			localStorage.getItem('plan_activated') === 'false') &&
		localStorage.getItem('trial') === 'false'
	) {
		return (
			<Redirect
				to={{
					pathname: '/plans',
				}}
			/>
		)
	} else
		return (
			<Container maxWidth='xl'>
				{url === '/encrypt-file' ? (
					<EncryptFile />
				) : url === '/encrypt-text' ? (
					<EncryptText />
				) : url === '/generate-url' ? (
					<GenerateURL />
				) : url === '/dashboard' ? (
					<Box
						sx={{
							display: 'flex',
							height: {
								lg: '70vh',
								md: '70vh',
								sm: '75vh',
								xs: '75vh',
								xl: '70vh',
							},
							marginTop: {
								md: '2.5rem',
								lg: '2.5rem',
								xl: '2.5rem',
								sm: '1.5rem',
								xs: '1.5rem',
							},
							flexDirection: {
								lg: 'row',
								md: 'row',
								sm: 'column',
								xs: 'column',
							},
							alignItems: 'center',
							borderRadius: '15px',
						}}
					>
						<Box
							sx={{
								width: {
									lg: '100%',
									md: '100%',
									sm: '100%',
									xs: '100%',
									xl: '100%',
								},
								display: 'flex',
								alignItems: 'center',
								border:'1px solid #3a3a3a',
								borderRadius: '12px',
								// background: '#b3e6ff',
								justifyContent: 'center',
								height: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
							}}
						>
							<Box
								sx={{
									width: '100%',
									height: '50%',
									textAlign: 'center',
								}}
							>
								<Box
									style={{ padding: '0 1rem' }}
									sx={{
										height: '80%',
										display: 'flex',
										textAlign: 'center',
										alignItems: 'center',
										borderRadius: '12px',
										justifyContent: 'space-between',
									}}
								>
									<Grid container layout={'row'} spacing={5}>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Box
												style={{ padding: '1rem' }}
												sx={{
													height: '100%',
													cursor: 'pointer',
													borderRadius: '12px',
													background: '#5FBFF9',
													':hover': {
														background: '#1976D2',
													},
												}}
												onClick={() => history.push('/encrypt-file')}
											>
												<Typography
													display='block'
													variant='overline'
													sx={{
														fontSize: {
															lg: '20px',
															md: '20px',
															sm: '15px',
															xs: '15px',
														},
														height: '100%',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													{t('encryptFile')}
												</Typography>
											</Box>
										</Grid>

										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Box
												style={{ padding: '1rem' }}
												sx={{
													height: '100%',
													cursor: 'pointer',
													borderRadius: '12px',
													background: '#5FBFF9',
													':hover': {
														background: '#1976D2',
													},
												}}
												onClick={() => history.push('/generate-url')}
											>
												<Typography
													style={{ whiteSpace: 'nowrap' }}
													display='block'
													variant='overline'
													sx={{
														fontSize: {
															lg: '20px',
															md: '20px',
															sm: '15px',
															xs: '15px',
														},
														height: '100%',
														display: 'flex',
														space: 'nowrap',
														cursor: 'pointer',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													{t('generateURL')}
												</Typography>
											</Box>
										</Grid>

										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Box
												style={{ padding: '1rem' }}
												sx={{
													height: '100%',
													cursor: 'pointer',
													borderRadius: '12px',
													background: '#5FBFF9',
													':hover': {
														background: '#1976D2',
													},
												}}
												onClick={() => history.push('/encrypt-text')}
											>
												<Typography
													style={{ whiteSpace: 'nowrap' }}
													display='block'
													variant='overline'
													sx={{
														fontSize: {
															lg: '20px',
															md: '20px',
															sm: '15px',
															xs: '15px',
														},
														height: '100%',
														display: 'flex',
														cursor: 'pointer',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													{t('encryptText')}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Container>
		)
}

export default Dashboard
