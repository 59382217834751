import {
	Snackbar,
	Typography,
	InputLabel,
	FormControl,
	OutlinedInput,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthService from '../../api-services/auth-service'
import SnackBar from '../../components/snackbar/SnackBar'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

const ForgetPassword = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorAlertOpen, setErrorAlertOpen] = useState(false)
	const [successAlertOpen, setSuccessAlertOpen] = useState(false)
	const [forgetPasswordForm, setForgetPasswordForm] = useState({ email: '' })

	const handleOnChange = e => {
		const { name, value } = e.target

		setForgetPasswordForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleForgetPassword = async e => {
		e.preventDefault()

		setLoading(true)

		const response = await AuthService.forgetPassword(forgetPasswordForm.email)

		if (!response.error) {
			setLoading(false)
			setSuccessAlertOpen(true)
			setErrorAlertOpen(false)
		} else if (response.error) {
			setLoading(false)
			setErrorAlertOpen(true)
			setSuccessAlertOpen(false)
			setError(response.msg)
		} else if (response === undefined) setLoading(false)
	}

	const handleClose = () => {
		setSuccessAlertOpen(false)
		setErrorAlertOpen(false)
	}

	return (
		<>
			{localStorage.getItem('authenticated') ? (
				<Redirect to='/login' />
			) : (
				<>
					<Box
						sx={{
							marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
							marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								marginTop: '8%',
								alignItems: 'center',
								border:'1px solid #3a3a3a',
								borderRadius: '12px',
								height: { lg: '70vh', md: '80vh', sm: '80vh', xs: '80vh' },
								flexDirection: {
									lg: 'row',
									md: 'column',
									sm: 'column',
									xs: 'column',
								},
							}}
						>
							<Box
								sx={{
									// background: '#b3e6ff',
									background: '#ffff',
									borderTopLeftRadius: '12px',
									borderBottomLeftRadius: { lg: '12px' },
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'center',
									height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
									width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
									borderTopRightRadius: {
										md: '12px',
										lg: 0,
										sm: '12px',
										xs: '12px',
									},
								}}
							>
								<Typography sx={{ mt: 4, mb: 2 }} variant='h6' component='div'>
									{t('enterValidEmailAddressToGetLink')}
								</Typography>
							</Box>
							<Box
								sx={{
									background: '#e6e6e6',
									borderBottomRightRadius: '12px',
									height: { lg: '100%', md: '50%' },
									borderTopRightRadius: { lg: '12px' },
									width: { lg: '45%', md: '100%', sm: '100%', xs: '100%' },
									borderBottomLeftRadius: {
										md: '12px',
										lg: 0,
										sm: '12px',
										xs: '12px',
									},
								}}
							>
								<Box
									sx={{
										mx: 8,
										height: '70%',
										marginTop: { lg: '25%' },
									}}
								>
									<Box>
										<Typography
											sx={{ fontSize: '20px' }}
											variant='button'
											display='block'
										>
											{t('enterValidEmailAddress')}
										</Typography>
									</Box>
									<Box
										sx={{
											height: '70%',
											mt: 5,
										}}
									>
										<form
											style={{ height: '100%', marginBottom: '20%' }}
											onSubmit={handleForgetPassword}
										>
											<Box
												sx={{
													textAlign: 'center',
												}}
											>
												<FormControl
													fullWidth
													sx={{ mt: 1 }}
													variant='outlined'
												>
													<InputLabel htmlFor='outlined-adornment-email-address'>
														{t('emailAddress')}
													</InputLabel>
													<OutlinedInput
														type='email'
														name='email'
														variant='outlined'
														label={t('emailAddress')}
														onChange={handleOnChange}
														id='outlined-adornment-email-address'
													/>
												</FormControl>
											</Box>

											<Box
												sx={{
													textAlign: 'center',
													mt: 2,
												}}
											>
												<LoadingButton
													type='submit'
													loading={loading}
													variant='contained'
													loadingPosition='end'
													endIcon={<ChangeCircleIcon />}
													disabled={forgetPasswordForm.email === ''}
												>
													{t('sendEmail')}
												</LoadingButton>
											</Box>
										</form>
									</Box>
								</Box>
							</Box>
						</Box>
						<SnackBar
							onClose={handleClose}
							severity={successAlertOpen ? 'success' : 'error'}
							message={successAlertOpen ? t('linkEmailed') : error}
							alertOpen={successAlertOpen ? successAlertOpen : errorAlertOpen}
						/>
					</Box>
				</>
			)}
		</>
	)
}

export default ForgetPassword
