import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TranslateIcon from '@mui/icons-material/Translate'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'

const VerifyEmailRedirect = () => {
	const { t, i18n } = useTranslation()

	const [anchorEl, setAnchorEl] = useState(null)
	const [language, setLanguage] = useState('en')

	const open = Boolean(anchorEl)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLangChange = lang => {
		setLanguage(lang)

		i18n.changeLanguage(lang)
	}

	return (
		<Box
			sx={{
				marginLeft: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
				marginRight: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
			}}
		>
			<Box
				sx={{
					display: 'flex',
					marginTop: '7%',
					flexDirection: {
						lg: 'row',
						md: 'column',
						sm: 'column',
						xs: 'column',
					},
					alignItems: 'center',
					border:'1px solid #3a3a3a',
					borderRadius: '12px',
					height: { lg: '80vh', md: '80vh', sm: '80vh', xs: '80vh' },
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						// background: '#b3e6ff',
						background: '#ffff',
						borderTopRightRadius: {
							md: '12px',
							lg: 0,
							sm: '12px',
							xs: '12px',
						},
						justifyContent: 'center',
						borderTopLeftRadius: '12px',
						borderBottomLeftRadius: { lg: '12px' },
						height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
						width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
					}}
				>
					<a
						target='_blank'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						href='https://identitysafe.de'
					>
						<img src='/IdentitySafe.webp' width='90%' />
						{/* <img src='/primaryIconLight.png' width='90%' /> */}
					</a>
				</Box>
				<Box
					sx={{
						background: '#e6e6e6',
						borderBottomLeftRadius: {
							lg: 0,
							md: '12px',
							sm: '12px',
							xs: '12px',
						},
						borderBottomRightRadius: '12px',
						height: { lg: '100%', md: '70%' },
						borderTopRightRadius: { lg: '12px' },
						width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
					}}
				>
					<Box
						sx={{
							float: 'right',
						}}
					>
						<IconButton
							color='inherit'
							id='long-button'
							aria-label='more'
							aria-haspopup='true'
							onClick={handleClick}
							aria-expanded={open ? 'true' : undefined}
							aria-controls={open ? 'long-menu' : undefined}
						>
							<TranslateIcon />
						</IconButton>
						<Menu
							open={open}
							id='long-menu'
							anchorEl={anchorEl}
							onClose={handleClose}
							PaperProps={{
								style: {
									width: '10ch',
									maxHeight: 48 * 4.5,
								},
							}}
							MenuListProps={{
								'aria-labelledby': 'long-button',
							}}
						>
							<MenuItem onClick={() => handleLangChange('en')}>
								{t('en')}
							</MenuItem>
							<MenuItem onClick={() => handleLangChange('de')}>
								{t('de')}
							</MenuItem>
						</Menu>
					</Box>
					<Box
						sx={{
							mx: 8,
							height: '70%',
							marginTop: { lg: '15%' },
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								mt: 5,
								height: '30%',
							}}
						>
							<Typography
								display='block'
								variant='overline'
								sx={{
									fontSize: {
										xs: '12px',
										lg: '20px',
										md: '20px',
										sm: '18px',
									},
									lineHeight: '1.4em',
									textAlign: 'center',
								}}
							>
								{t('goodDay')},
								<br />
								{t('emailActivationRedirectText')}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default VerifyEmailRedirect
