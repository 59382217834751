import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	InputLabel,
	FormControl,
	DialogTitle,
	DialogActions,
	DialogContent,
	OutlinedInput,
	DialogContentText,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'

const EditDialogBox = ({
	open,
	editUser,
	editPlan,
	updating,
	handleClose,
	editUserForm,
	editPlanForm,
	handleOnChange,
}) => {
	const { t, i18n } = useTranslation()

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				{editUser === undefined ? t('editPlanDetails') : t('editUserDetails')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('aboutToEdit')}{' '}
					{editUserForm === undefined
						? editPlanForm.plan_name
						: editUserForm.fullname}
					's {t('lang') === 'de' ? '' : t('info')}
				</DialogContentText>
				<form onSubmit={editUser}>
					{editUser !== undefined ? (
						<Box
							sx={{
								textAlign: 'center',
							}}
						>
							<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
								<InputLabel htmlFor='outlined-adornment-fullname'>
									{t('fullname')}
								</InputLabel>
								<OutlinedInput
									type='text'
									name='fullname'
									label={t('fullname')}
									variant='outlined'
									onChange={handleOnChange}
									value={editUserForm.fullname}
									id='outlined-adornment-fullname'
								/>
							</FormControl>

							{editUserForm.plan_activated ? (
								<></>
							) : (
								<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
									<InputLabel htmlFor='outlined-adornment-trial-duration'>
										{t('trialDuration')}
									</InputLabel>
									<OutlinedInput
										inputProps={{
											min: '1',
										}}
										type='number'
										variant='outlined'
										name='trialDuration'
										onChange={handleOnChange}
										label={t('trialDuration')}
										value={editUserForm.duration}
										id='outlined-adornment-fullname'
									/>
								</FormControl>
							)}
						</Box>
					) : (
						<>
							<Box
								sx={{
									textAlign: 'center',
								}}
							>
								<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
									<InputLabel htmlFor='outlined-adornment-planeName'>
										{t('planName')}
									</InputLabel>
									<OutlinedInput
										type='text'
										name='plan_name'
										label={t('planName')}
										variant='outlined'
										onChange={handleOnChange}
										value={editPlanForm.plan_name}
										id='outlined-adornment-planeName'
									/>
								</FormControl>
							</Box>

							<Box
								sx={{
									textAlign: 'center',
								}}
							>
								<FormControl fullWidth sx={{ mt: 1 }}>
									<InputLabel htmlFor='outlined-adornment-description'>
										{t('description')}
									</InputLabel>
									<OutlinedInput
										type='text'
										name='description'
										variant='outlined'
										label={t('description')}
										onChange={handleOnChange}
										value={editPlanForm.description}
										id='outlined-adornment-description'
									/>
								</FormControl>
							</Box>

							{editPlanForm.is_trial ? (
								<Box
									sx={{
										textAlign: 'center',
									}}
								>
									<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
										<InputLabel htmlFor='outlined-adornment-duration'>
											{t('duration')}
										</InputLabel>
										<OutlinedInput
											inputProps={{
												min: '1',
											}}
											type='number'
											name='duration'
											variant='outlined'
											label={t('duration')}
											onChange={handleOnChange}
											value={editPlanForm.duration}
											id='outlined-adornment-duration'
										/>
									</FormControl>
								</Box>
							) : (
								<></>
							)}

							{editPlanForm.price === 0 ? (
								<div></div>
							) : (
								<Box
									sx={{
										textAlign: 'center',
									}}
								>
									<FormControl fullWidth sx={{ mt: 1 }}>
										<InputLabel htmlFor='outlined-adornment-price'>
											{t('price')}
										</InputLabel>
										<OutlinedInput
											inputProps={{
												min: '1',
											}}
											name='price'
											type='number'
											label={t('price')}
											variant='outlined'
											onChange={handleOnChange}
											value={editPlanForm.price}
											id='outlined-adornment-price'
										/>
									</FormControl>
								</Box>
							)}
						</>
					)}
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{t('cancel')}</Button>
				<LoadingButton
					type='submit'
					variant='text'
					loading={updating}
					disabled={updating}
					loadingPosition='start'
					startIcon={<EditIcon />}
					onClick={editUserForm !== undefined ? editUser : editPlan}
				>
					{t('edit')}
				</LoadingButton>
				{/* <Button
					disabled={updating}
					onClick={editUserForm !== undefined ? editUser : editPlan}
				>
					{t('edit')}
				</Button> */}
			</DialogActions>
		</Dialog>
	)
}

export default EditDialogBox
