import axios from 'axios'

const SubscriptionService = {
  subscribe: (data) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/subscription/enable?user_id=${localStorage.getItem('user_id')}&subscription_id=${data.subscriptionId}&plan_id=${data.planId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  cancel: (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/subscription/cancel?user_id=${localStorage.getItem('user_id')}&subscription_id=${localStorage.getItem('subscription_id')}`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  }
}

export default SubscriptionService