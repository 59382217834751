import React from 'react'

const Loader = () => (
	<div className='App' style={{overflow:"hidden"}}>
		{/* <img src={logo} className='App-logo' alt='logo' /> */}
		<div>loading...</div>
	</div>
)

export default Loader
