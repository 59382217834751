import { Container, Typography } from '@mui/material'
import React from 'react'
import { Box } from '@mui/system'
import ClipboardJS from 'clipboard'
import { useHistory, useRouteMatch } from 'react-router-dom'
import EncryptFile from '../../components/encryption/file/EncryptFile'
import EncryptText from '../../components/encryption/text/EncryptText'
import { useTranslation } from 'react-i18next'

const NoAuthDashboard = () => {
	new ClipboardJS('#copyButton')
	new ClipboardJS('#copyButtonText')

	const history = useHistory()
	const { url } = useRouteMatch()
	const { t, i18n } = useTranslation()

	return (
		<Container maxWidth='lg'>
			{url === '/guest-encrypt-file' ? (
				<EncryptFile />
			) : url === '/guest-encrypt-text' ? (
				<EncryptText />
			) : url === '/guest-dashboard' ? (
				<Box
					sx={{
						display: 'flex',
						marginTop: '3rem',
						alignItems: 'center',
						borderRadius: '15px',
						// minHeight:"70vh",
            			justifyContent:"center"
						
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							// background: '#b3e6ff',
							background: '#ffff',
							border:'1px solid #3a3a3a',
							borderRadius: '12px',
						  minWidth:"80vw",
						  minHeight:"70vh",
              
						}}
					>
						<Box
							sx={{
								// marginTop: { lg: "15%", md: '2%', sm: '2%', xs: '2%' },
								textAlign: 'center',
								
							}}
						>
							<Box
								sx={{
									height: '100%',
									display: 'flex',
									textAlign: 'center',
							
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: '12px',
                  flexDirection: {
                    lg: 'row',
                    md: 'row',
                    sm: 'row',
                    xs: 'column',
                  },
								}}
							>
								<Box
									sx={{
										px: 2,
                    height: {lg:'200px',sm:'150px',md:'200px',xl:'300px',xs:"150px"},
                    
										background: '#5FBFF9',
										borderRadius: '12px',
										marginRight:{lg:"4rem",md:"4rem",xl:"5rem",xs:0,sm:"4rem"},
										':hover': {
											background: '#1976D2',
										},
										cursor: 'pointer',
                   
									}}
                  
									onClick={() => {
										if (url === '/guest-dashboard')
											history.push('/guest-encrypt-file')
										else if (url === '/dashboard') history.push('/encrypt-file')
									}}
								>
									<Typography
										display='block'
										variant='overline'
										sx={{
											fontSize: {
												lg: '20px',
												md: '20px',
												sm: '15px',
												xs: '15px',
											},
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										{t('encryptFile')}
									</Typography>
								</Box>
								<Box
									sx={{
										px: 2,
                    height: {lg:'200px',sm:'150px',md:'200px',xl:'300px',xs:"150px"},
										background: '#5FBFF9',
										borderRadius: '12px',
										':hover': {
											background: '#1976D2',
										},
										cursor: 'pointer',
                    marginTop:{lg:0,md:0,sm:0,xs:"1rem",xl:0}
									}}
									onClick={() => {
										if (url === '/guest-dashboard')
											history.push('/guest-encrypt-text')
										else if (url === '/dashboard') history.push('/encrypt-text')
									}}
								>
									<Typography
										display='block'
										variant='overline'
										sx={{
											fontSize: {
												lg: '20px',
												md: '20px',
												sm: '15px',
												xs: '15px',
											},
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										{t('encryptText')}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<></>
			)}
		</Container>
	)
}

export default NoAuthDashboard
