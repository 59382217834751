import axios from 'axios'
import { t } from 'i18next'

export const EncryptionService = {
  hasData: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/has_data`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  generateURL: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/generate_url`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  encryptData: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/encrypt`, data, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          return percentCompleted
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  encryptDataGuest: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/guest_encrypt?lang=${t('lang')}`, data, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          return percentCompleted
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  decryptData: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/decrypt`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  decryptDataGuest: data => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/encrypt/guest_decrypt`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getFile: data => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/encrypt/get_file?file_path=${data}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getFileGuest: data => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/encrypt/guest_get_file?file_path=${data}`,
        {
          responseType: 'blob',
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  deleteFile: data => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/encrypt/delete_file?file_path=${data}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  deleteFileGuest: data => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/encrypt/guest_delete_file?file_path=${data}`
      )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
}

export default EncryptionService
