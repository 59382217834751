import React from 'react'
import PropTypes from "prop-types"
import { Redirect, Route } from 'react-router-dom'

const RouteMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && (localStorage.getItem('authenticated') === null || localStorage.getItem('authenticated') === 'false'))
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )

        // if (isAuthProtected && (localStorage.getItem('authenticated') !== null || localStorage.getItem('authenticated') === 'true')) {

        //   if (isAuthProtected && (localStorage.getItem('plan_activated') === null || localStorage.getItem('plan_activated') === 'false'))
        //     return (
        //       <Redirect
        //         to={{
        //           pathname: "/plans",
        //           state: { from: props.location }
        //         }}
        //       />
        //     )
        // }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

RouteMiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  isAuthProtected: PropTypes.bool
}

export default RouteMiddleware
