import { Suspense } from 'react'
import Loader from './components/Loader/Loader'
import noAuth from './components/layouts/noAuth'
import RouteMiddleware from './routes/middleware'
import vertical from './components/layouts/vertical'
import { BrowserRouter, Switch } from 'react-router-dom'
import { customAuthRoutes, protectedRoutes, publicRoutes } from './routes'

const App = () => {
	// setInterval(async() => {
	//   const response = await NotificationService.checkForNotification()

	// }, 60000);

	return (
		<>
			<Suspense fallback={<Loader />}>
				<BrowserRouter>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<RouteMiddleware
								exact
								key={idx}
								layout={noAuth}
								path={route.path}
								isAuthProtected={false}
								component={route.component}
							/>
						))}

						{protectedRoutes.map((route, idx) => (
							<RouteMiddleware
								key={idx}
								layout={vertical}
								path={route.path}
								// isAuthProtected={true}
								isAuthProtected={false}
								component={route.component}
							/>
						))}

						{customAuthRoutes.map((route, idx) => (
							<RouteMiddleware
								key={idx}
								layout={vertical}
								path={route.path}
								isAuthProtected={false}
								component={route.component}
							/>
						))}
					</Switch>
				</BrowserRouter>
			</Suspense>
		</>
	)
}

export default App
